import Header from "../common/Header";
import Footer from "../common/Footer";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const styles = {
    reviewHeading: {
        color: "black"
    },
    reviewName: {
        color: "black"
    },
    reviewText: {
        color: "black"
    }
}

function Review() {

    const apiURL = process.env.REACT_APP_API_URL

    const [reviewBColor, setRBColor] = useState("");

    const [reviewData, setRData] = useState([]);

    useEffect(() => {
        fetchFn();
    }, [])

    function fetchFn() {
        fetch(`${apiURL}/user/fetchReview`).then((result) => {
            return result.json();
        }).then((data) => {
            if (data.status === 200) {
                setRBColor(data.data.BColor);
            } else {
                toast(data.message, { type: "error" });
            }
        })
        fetch(`${apiURL}/user/fetchAllReview`).then((result) => {
            return result.json();
        }).then((data) => {
            if (data.status === 200) {
                setRData(data.data);
            } else {
                toast(data.message, { type: "error" });
            }
        })
    }

    return (
        <>
            <ToastContainer position="top-center" />
            <Header />
            <section id="review-page">
                <div className="container">
                    <div className="row">
                        <div style={styles.reviewHeading} className="col-sm-12 d-sm-block d-none">
                            <p className="text-center font-family fw-bolder mt-5 review-heading">WHAT OUR STUDENTS SAY</p>
                        </div>
                        <div style={styles.reviewHeading} className="col-sm-12 d-sm-none d-block ">
                            <p className="text-center font-family fw-bolder mt-4 fs-4">WHAT OUR STUDENTS SAY</p>
                        </div>
                    </div>
                </div>
                <div className="container my-sm-5 my-4">
                    {reviewData.length !== 0 ?
                        <div className="row">
                            {reviewData.map((data) => (
                                <div key={data._id} className="col-sm-4 p-2">
                                    <div style={{ backgroundColor: reviewBColor }} className="p-4">
                                        <p style={styles.reviewName} className="text-center d-sm-block d-none fw-bold font-family student-name">{data.name}</p>
                                        <p style={styles.reviewText} className="text-center d-sm-block d-none fw-normal m-0 font-family student-text">{data.review}</p>
                                        <p style={styles.reviewName} className="text-center d-sm-none d-block fw-bold font-family fs-4">{data.name}</p>
                                        <p style={styles.reviewNreviewTextame} className="text-center d-sm-none d-block fw-normal m-0 font-family student-text-mob">{data.review}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="row">
                            <div style={{ backgroundColor: reviewBColor }} className="col-sm-12 p-2">
                                <p className="text-center d-sm-block d-none font-family fw-bolder fs-1 m-0">NO REVIEWS HERE</p>
                                <p className="text-center d-sm-none d-block font-family fw-bolder fs-5 m-0">NO REVIEWS HERE</p>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Review;